<title>Titanium™</title>

<ngx-ui-loader></ngx-ui-loader>
<router-outlet></router-outlet>
<div *ngIf="showScrol" (click)="scrollTo('banner')" class="abDiv">
  <div class="text">
    <h3 *ngIf="index<=1; else fadetext" style="color: white; opacity: 0%;">Scroll Down</h3>
    <ng-template #fadetext>
      <h3 style="color: white; opacity: 0%;">Scroll Down</h3>
    </ng-template>
  </div>
  <div class="downArrow" id="scrollMid">
  </div>
</div>


