<section class="map-form-section-embedded login-form">
  <div class="map-form" *ngIf="steps == 1">
    <div class="login-form-group">
      <h3 class="topText" *ngIf="steps == 1 && !showOTP">Enter your Phone Number</h3>
      <div class="form-group">
        <div id="flex" class="form-control">
          <div>
            <form autocomplete="off" [formGroup]="loginForm" id="contact-form">
              <ul>
                <li class="country-dropdown" *ngIf="!showOTP">
                  <!--<label>Phone Number</label>-->
                  <ngx-intl-tel-input
                    [ngClass]="( g['phone_number'].touched && g['phone_number']?.errors?.required || g['phone_number'].touched && g['phone_number']?.errors?.validatePhoneNumber ? 'form-error' : '')"
                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true" [customPlaceholder]="'Phone Number'" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                    [phoneValidation]="true" [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.International" name="phone" formControlName="phone_number">
                  </ngx-intl-tel-input>
                </li>
                <li *ngIf="g.phone_number.touched && g.phone_number.invalid">
                  <div *ngIf="g.phone_number.hasError('required') || g.phone_number.hasError('validatePhoneNumber')">
                    <div class="text-danger">
                      Invalid Phone Number!
                    </div>
                  </div>
                </li>
                <li *ngIf="showOTP">
                  <h3 class="topText">Verify by Phone OTP</h3>
                  <p style = "color:black">
                    Enter the 4 digit OTP sent to:
                    <strong>+{{phone_number}}</strong>
                  </p>

                  <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                    (fill)="handleFillEvent($event)"></ngx-otp-input>
                  <!-- <input [ngClass]="( signSubmit && g['password']?.errors?.required ? 'form-error' : '')"
      type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                  <span *ngIf="showResendOTP" style="margin-top: 10px;margin-bottom: 10px;"
                    class="resend-otp-text pointer" (click)="resendOTP()">
                    Resend OTP
                  </span>
                  <span *ngIf="!showResendOTP" style="margin-top: 10px;margin-bottom: 10px;" class="resend-otp-text">
                    Re-send otp in {{counter|formatTime}}
                  </span>
                </li>
                <li *ngIf="error && showOTP" class="show__alert__message">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="error" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger" *ngIf="!showOTP">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-success">
                    {{success}}
                  </div>
                </li>
                <li>
                  <!-- <button class="get-otp-btn" type="button" (click)="verifyUserSubscription()">{{windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'}}</button>    -->
                  <input *ngIf="!showOTP" type="button" class="btn btn-blue submitBtn"
                    [disabled]="g.phone_number.invalid" (click)="verifyUserSubscription()" name="submit"
                    [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Get One Time Password'" />
                  <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkPhoneOTP()"
                    name="submit" value="Continue" />
                </li>
              </ul>
            </form>
          </div>
          <div id="emailIsChecked" style="display: none; margin-bottom: -17px;">
            <form autocomplete="off" [formGroup]="loginForm" id="contact-form">
              <ul>
                <!--<h6>Enter your Email Address :</h6>-->
                <li *ngIf="!showOTP">
                  <input
                    [ngClass]="(g['email_id']?.errors?.required || g['email_id']?.errors?.email ? 'form-error' : '')"
                    type="email" formControlName="email_id" name="email_id" placeholder="Email Address" email />
                </li>
                <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="success" class="show__alert__message">
                  <div class="alert alert-success">
                    {{success}}
                  </div>
                </li>
                <li *ngIf="!showOTP">
                  <input type="button" class="btn btn-orange submitBtn" [disabled]="g.email_id.invalid"
                    (click)="verifyUserSubscription()" name="submit" value="Get One Time Password" />
                </li>
                <li *ngIf="showOTP">
                  <h6>Verify by Email Verification</h6>
                  <p>
                    Enter the 4 digit verification code sent to:
                    <strong>{{g['email_id'].value}}</strong>
                  </p>

                  <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                    (fill)="handleFillEvent($event)"></ngx-otp-input>
                  <!-- <input [ngClass]="( signSubmit && g['password']?.errors?.required ? 'form-error' : '')"
      type="text" autocomplete="off" formControlName="password" name="otp" placeholder="OTP" /> -->

                  <span *ngIf="showResendOTP" style="margin-top: 10px;margin-bottom: 10px;"
                    class="resend-otp-text pointer" (click)="resendOTP()">
                    Resend OTP
                  </span>
                  <span *ngIf="!showResendOTP" style="margin-top: 10px;margin-bottom: 10px;" class="resend-otp-text">
                    Re-send otp in {{counter|formatTime}}
                  </span>
                </li>
                <li *ngIf="error && showOTP" class="show__alert__message">
                  <div class="alert alert-danger">
                    {{error}}
                  </div>
                </li>
                <li *ngIf="success" class="show__alert__message">
                  <div class="alert alert-success">
                    {{success}}
                  </div>
                </li>
                <li>
                  <!-- <button class="get-otp-btn" type="button" (click)="verifyUserSubscription()">{{windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'}}</button>    -->
                  <!--<input *ngIf="!showOTP" type="button" class="btn btn-blue submitBtn" (click)="verifyUserSubscription()" name="submit" [value]="windowRef?.confirmationResult ? 'Resend OTP' : 'Get OTP'" />-->
                  <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkPhoneOTP()"
                    name="submit" value="Continue" />
                </li>
              </ul>
            </form>
          </div>
          <!--<div id="phoneEmail" *ngIf="!showOTP">
          Email <input type="radio" id="emailCheck" value="Email" (click)="selectedOption()" onclick="document.getElementById('contact-form').reset()" name="phone_or_email" checked={{emailCheck}}>
          Phone <input type="radio" id="phoneCheck" value="Phone" (click)="selectedOption()" onclick="document.getElementById('contact-form').reset()" name="phone_or_email" checked={{phoneCheck}}>
        </div>-->
        </div>

        <div id="recaptcha-container"></div>
      </div>
    </div>
    <div class="bottom-slide-icons">
      <div class="active"></div>
      <div></div>
    </div>
  </div>
  <div class="payment-header" *ngIf="steps == 2 && !ShowThankyouScreen && !ShowResetScreen">
    <div class="payment-logo">
      <span style="padding-top: 0px !important; font-size: 0.5rem !important; margin-left: 45px !important; margin-top:5px!important" class="trademark">™</span>
      <img src="./assets/images/logo.png" />
      <h3>Password</h3>
    </div>
  </div>
  <div class="map-form" *ngIf="steps == 2">
    <div class="wrapper" *ngIf="!showNumberNotFound && !ShowThankyouScreen && !ShowResetScreen && steps == 2">
 
      
        <div style="padding-bottom: 450px;">
          <div class="login-form-group" [@slideInOut]>
            <h3 class="addedMargin">Amount</h3>
            <h3 *ngIf="!showOTP" class="topText">Please enter your desired password.</h3>
            <div class="form-group">
              <div id="emailOrPhoneCheck">
                <form autocomplete="on" [formGroup]="changePasswordForm" id="signup-form">
                  <ul>
                    <li *ngIf="!showOTP">
                      <!-- <p style="text-align: center;">New Password</p> -->
                      <input id="cpass" [ngClass]="( submit && g['new_password']?.errors?.required ? 'form-error' : '')"
                        type="password" formControlName="new_password" name="new_password" placeholder="Password" />
                      <span id="shcpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                        onClick="viewCPassword()"></span>
                    </li>
                    <li *ngIf="!showOTP">
                      <!-- <p style="text-align: center;">New Password</p> -->
                      <input id="ccpass"
                        [ngClass]="( submit && g['confirm_new_password']?.errors?.required ? 'form-error' : '')"
                        type="password" formControlName="confirm_new_password" name="confirm_new_password"
                        placeholder="Confirm Password" />
                      <span id="shccpass" class="fa fa-eye-slash field-icon" aria-hidden="true"
                        onClick="viewCCPassword()"></span>
                    </li>
                    <li *ngIf="!showOTP">
                      <input type="submit" id="submit" style="margin-top: 10px;margin-bottom: 10px;"
                        [disabled]="!changePasswordForm.valid" class="btn btn-orange submitBtn"
                        (click)="changePassword()" name="submit" value="Confirm">
                    </li>
                    <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                      <div class="alert alert-danger">
                        {{error}}
                      </div>
                    </li>
                    <li *ngIf="passwordNotMatchError" class="show__alert__message" style="padding-bottom: 5px;">
                      <div class="alert alert-danger">
                        {{passwordNotMatchError}}
                      </div>
                    </li> 
                    <li *ngIf="error && showOTP" class="show__alert__message">
                      <div class="alert alert-danger">
                        {{error}}
                      </div>
                    </li>
                    <li *ngIf="error && !showOTP" class="show__alert__message" style="padding-bottom: 5px;">
                      <div class="alert alert-danger">
                        {{error}}
                      </div>
                    </li>
                    <li *ngIf="success" class="show__alert__message" style="padding-bottom: 5px;">
                      <div class="alert alert-success">
                        {{success}}
                      </div>
                    </li>
                    <li>
                      <input *ngIf="showOTP" type="button" class="btn btn-blue submitBtn" (click)="checkPhoneOTP()"
                        name="submit" value="Confirm" />
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      
    </div>


  </div>

</section>
<div class="wrapper" *ngIf="showNumberNotFound && !ShowThankyouScreen && !ShowResetScreen">
  <div class="thankyou-bg">
    <div style="padding-top: 90px;" class="logo pointer">
      <img src="./assets/images/logo.png" />
      <span style="margin-left: 90px !important; margin-top: 20px !important" class="trademark2">™</span>
    </div>
    <div class="thankyou-box box-shadow">
      <p1 style="color:black;">Phone number incorrect. Please contact your organizer.</p1>
      <div class="download-btn-group">
      </div>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="ShowThankyouScreen && !showNumberNotFound">
  <div class="thankyou-bg">
    <div class="logo pointer">
      <img (click)="goTOHome()" src="./assets/images/logo.png" />
      <span style="margin-left: 90px !important; margin-top: 20px !important" class="trademark2">™</span>
    </div>
    <div class="thankyou-box box-shadow">
      <img src="./assets/images/thankyou-img.png" />
      <h3>Welcome to Titanium™!</h3>
      <p>Your account was successfully activated! Simply click a link download the app and use your login information to sign in:</p>
      <div class="download-btn-group">
    <a href="https://apps.apple.com/us/app/titanium-secure-messaging/id6455696374" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-apple"></i> iOS</a>
    <a href="https://www.titaniumsecure.io:3000/download/getlatestapk" target="_black"style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-android"></i> Android (APK)</a>
    <!--<a href="https://play.google.com/store/apps/details?id=io.titanium" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-google-play"></i> Android (Google Play)</a>-->
    <a href="https://www.titaniumsecure.io:3000/download/getlatestmac" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-app-store-ios"></i> MacOS</a>
    <a href="https://www.titaniumsecure.io:3000/download/getlatestwin" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-windows"></i> Windows</a>
      </div>
    </div>
  </div>
</div>

<div class="wrapper" *ngIf="ShowResetScreen && !showNumberNotFound">
  <div class="thankyou-bg">
    <div class="logo pointer">
      <img (click)="goTOHome()" src="./assets/images/logo.png" />
      <span style="margin-left: 90px !important; margin-top: 20px !important" class="trademark2">™</span>
    </div>
    <div class="thankyou-box box-shadow">
      <img src="./assets/images/thankyou-img.png" />
      <h3>Welcome to Titanium™!</h3>
      <p>Your password has been successfully reset! If you already have the app, use your updated login information to sign in. If you don't have the app yet, download it using one of the links below:</p>
      <div class="download-btn-group">
    <a href="https://apps.apple.com/us/app/titanium-secure-messaging/id6455696374" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-apple"></i> iOS</a>
    <a href="https://www.titaniumsecure.io:3000/download/getlatestapk" target="_black"style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-android"></i> Android (APK)</a>
    <!--<a href="https://play.google.com/store/apps/details?id=io.titanium" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-google-play"></i> Android (Google Play)</a>-->
    <a href="https://www.titaniumsecure.io:3000/download/getlatestmac" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-app-store-ios"></i> MacOS</a>
    <a href="https://www.titaniumsecure.io:3000/download/getlatestwin" target="_black" style="margin-top: 15px;" class="btn btn-blue pointer"><i class="fab fa-windows"></i> Windows</a>
      </div>
    </div>
  </div>
</div>
