import { Component, OnInit, TemplateRef } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { CommonHttpClient } from '../shared/services/common/common-http.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
declare function viewPassword(): any;
declare function viewCPassword(): any;
declare function viewCCPassword(): any;

@Component({
  selector: 'app-validation-otp',
  templateUrl: './validation-otp.component.html',
  styleUrls: ['./validation-otp.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class ValidationOtpComponent implements OnInit {
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: "my-super-box-class",
      input: "my-super-class",
      inputFilled: "my-super-filled-class",
      inputDisabled: "my-super-disable-class",
      inputSuccess: "my-super-success-class",
      inputError: "my-super-error-class"
    }
  };
  //get from html forms
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  newPassword: string;
  error: string;
  PasswordChanged: boolean = false;
  changePasswordForm: FormGroup;
  submit: boolean = false;
  passwordNotMatchError: string;
  closeResult = '';
  phoneCheck = false;
  emailCheck = false;
  steps = 1;
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  success: any;
  changePhoneByPhoneCheck: boolean;

  loginForm: FormGroup;
  ConfirmationToken: string;
  response: string;
  phone_number = '';
  
  windowRef: any;
  apiCalled: boolean;
  //fam_id: string;
  email: string;
  phone: string;
  userId: any;
  localPhone = '';
  localEmail = '';
  confirmation_token: string;
  fam_id: string;
  emailOTP: boolean = false;
  phoneOTP: boolean = false;
  OTPcounter: number = 0;
  showOTP: boolean = false; //this should false when init
  showResendOTP: boolean = false // to show resend otp button -- should be false when init
  showNumberNotFound: boolean = false;
  ShowThankyouScreen: boolean = false;
  ShowResetScreen: boolean = false;

  constructor(private userService: UserService, private _location: Location, private router: Router, private ngxloader: NgxUiLoaderService,
    private userAuth: UserAuthService, route: ActivatedRoute, private ccService: NgcCookieConsentService, private commonHttpInit: CommonHttpClient) {
      this.confirmation_token = route.snapshot.params.token;
      this.initChangePasswordForm();
    this.initCommonHTTP();
    this.initLoginForm();
    
  }

  ngOnInit(): void {
    this.ccService.destroy();
  }
  async initCommonHTTP() {
    this.ngxloader.start();
    var key = await this.commonHttpInit.getApiKey()
    if (key) {
      //this.getSubscriptionDetails();
      this.ngxloader.stop();
    }
  }
  initLoginForm() {
    this.loginForm = new FormGroup({
      phone_number: new FormControl('', [Validators.required, Validators.nullValidator, Validators.maxLength(50)]),
      email_id: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.maxLength(254)]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      OtpCode: new FormControl('', [Validators.required]),
    }, { validators: this.passwordMatchingValidatior });
  }
  
  changePassword() {
    this.ngxloader.start();
    this.clearInfoMessages();
    this.newPassword = this.changePasswordForm.get("new_password").value;
    this.phone = this.loginForm.get("phone_number").value;
    if (this.phone != null) {
      if (this.phone) {
        let OTPinput = this.changePasswordForm.get('OtpCode').value;
        this.userService.changePasswordByPhoneFromLink(this.newPassword, this.phone_number, OTPinput).subscribe(resp => { //change
          if (resp.message === 'Password changed successfully!') {
            // this.userService.changePasswordFromlink(this.newPassword, this.userId, '+' + this.phone_number).subscribe(famResp => {
            //   if (famResp.message === 'Password changed successfully!') {
                
                this.PasswordChanged = true;
                //activate users
                //success pop up
                this.checkIfTokenIsValid();
            //   }
            // });
          }
          else {
            if (resp.message === 'Old password is incorrect.') {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
            if (resp.message === "Invalid Password! Password is the same as the current one!") {
              this.passwordNotMatchError = resp.message;
              this.ngxloader.stop();
            }
          }
        }, error => { this.passwordNotMatchError = "Invalid Password! Password is the same as the current one!" })
      }
    }
    else {
      this.passwordNotMatchError = 'Error changing password!'
    }
  }
  checkIfTokenIsValid()
  {
    if (this.confirmation_token != "reset") {      
      this.userService.verifyTokenByPhone(this.phone_number, this.confirmation_token).subscribe((resp) => {
        if (resp.message === 'token matches!') {
          this.fam_id = resp.fam_id;
          this.userService.activateMongoUserByPhone(this.phone_number).subscribe((resp) => {
            if (resp.message === 'activated') {
              this.userService.activateFlexisipUser(this.fam_id).subscribe((resp) => {
                this.ShowThankyouScreen = true;
                this.ngxloader.stop();
              });
            }
          });
        }
        else {
          this.ShowThankyouScreen = false;
          this.ngxloader.stop();
        }
      });
    }
    else
    {
      this.ShowResetScreen = true;
      this.ngxloader.stop();
    }
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  get g() {
    return this.loginForm.controls;
  }

  initChangePasswordForm() {
    this.changePasswordForm = new FormGroup({
      new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      confirm_new_password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/)]),
      OtpCode: new FormControl('', [Validators.required])
    }, { validators: this.passwordMatchingValidatior });
  }

  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }

  }
  clearPasswordNotMatchError() {
    this.passwordNotMatchError = null;
  }

  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get('new_password');
    const confirm_new_password = control.get('confirm_new_password');

    if (confirm_new_password) {
      if (confirm_new_password.value != '' && newPassword.value != '' && confirm_new_password.value != null && newPassword.value != null) {
        var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/);
        let newCurrentPassword = newPassword.value;
        var result = newCurrentPassword.match(pattern);
      }

      if (newPassword.value != confirm_new_password.value && newPassword.value != null && confirm_new_password.value != null && newPassword.value != '' && confirm_new_password.value != '' &&
        confirm_new_password.value != newPassword) {
        this.passwordNotMatchError = "New Password does not match the Confirm New Password!"
        this.changePasswordForm.invalid;
      }
      else {
        this.clearPasswordNotMatchError();
      }
      if (confirm_new_password.value === newPassword.value && confirm_new_password.value != null && newPassword.value != null && confirm_new_password.value != '' && newPassword.value != '') {
        if (!result) {
          this.passwordNotMatchError = "Password must be at least eight characters, with one uppercase letter, one lowercase letter, one number and one special character."
        } else {
          this.clearPasswordNotMatchError();
        }
      }
    }
    return newPassword?.value === confirm_new_password?.value ? null : { notmatched: true };
  };

  clearInfoMessages() {
    this.error = null;
    this.passwordNotMatchError = null;
    this.success = null;
  }

  getSubscriptionDetails() {
    this.ngxloader.start();
    this.phone = this.loginForm.get("phone_number").value;
  }



  initOTP() {
    if (!this.showNumberNotFound) {
      this.clearInfoMessages();
      if (this.localPhone) {
        this.ngxloader.start();
        this.userService.getPhoneOTP(this.localPhone).subscribe(resp => {
          if (resp.OTP) {
            this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.phoneOTP = true;
                this.showOTP = true;
                this.startOTPTimer();
                var data = {
                  otp: resp.otp,
                  phone: this.localPhone,
                  otp_sent_counter: 0,
                  otp_sent_date: new Date(),
                  telesign_ref_id: resp.telesign_ref_id
                }

              }
              this.ngxloader.stop();
            });
          }
          else {
            this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.phoneOTP = true;
                this.showOTP = true;
                this.startOTPTimer();
                var data = {
                  otp: resp.otp,
                  phone_number: this.localPhone,
                  otp_sent_counter: this.OTPcounter,
                  otp_sent_date: new Date(),
                  telesign_ref_id: resp.telesign_ref_id
                }
   
              }
              this.ngxloader.stop();
            });
          }
        });

      }
    }
  }

  checkPhoneOTP() {
    if (this.phone_number) {
      let OTPinput = this.changePasswordForm.get('OtpCode').value;
      this.userService.checkPhoneOTP(this.phone_number, OTPinput).subscribe(resp => {
        /* if (res.OTP) {*/
        if (resp.message == 'OTP matches!') {

          this.clearInfoMessages();
          this.showOTP = false;
          this.steps = 2;
          //delete temptable record
          // this.userService.deleteTempPhoneRecord(this.localPhone).subscribe(resp => {            

          // });
        }

        else {
          this.error = "Invalid Code!"
        }
      }

      )
    }
    if (this.email) {
      let OTPinput = this.changePasswordForm.get('OtpCode').value;
      this.userService.checkEmailOTP(this.email, OTPinput).subscribe(resp => {
        /* if (res.OTP) {*/
        if (resp.message == 'OTP matches!') {


          this.clearInfoMessages();
          this.showOTP = false;
          this.steps = 2;
          //delete temptable record
          // this.userService.deleteTempEmailRecord(this.localEmail).subscribe(resp => {

          // });
        }
        else {
          this.error = "Invalid Code!"
        }
      }

      )
    }

  }

  resendOTP() {
    if (this.localPhone) {
      this.ngxloader.start();
      this.userService.getPhoneOTP(this.localPhone).subscribe(resp => {
        this.OTPcounter = resp.counter;
        if (this.OTPcounter < 3) {
          this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
            if (resp.message === 'Phone OTP was successfully sent!') {
              this.showOTP = true;
              this.startOTPTimer();
              this.ngxloader.stop();
              this.OTPcounter = this.OTPcounter + 1;
              var data = {
                telesign_ref_id: resp.telesign_ref_id,
                otp: resp.otp,
                phone: this.localPhone,
                otp_sent_counter: this.OTPcounter,
                otp_sent_date: new Date()
              }
  
              this.ngxloader.stop();
            } else {
              this.ngxloader.stop();
            }
          });
        }
        else {
          var dateHours;
          var DateNow = new Date();
          var dateSpan = new Date(resp.OTPSentDate);
          if (dateSpan.getHours() != 23) {
            dateHours = dateSpan.getHours() + 1
          }
          else {
            dateHours = 0;
          }
          dateSpan.setHours(dateHours);
          if (DateNow >= dateSpan) {
            this.ngxloader.start();
            this.userService.sendPhoneOTP(this.localPhone).subscribe(resp => {
              if (resp.message === 'Phone OTP was successfully sent!') {
                this.showOTP = true;
                this.startOTPTimer();
                this.OTPcounter = this.OTPcounter + 1;
                var data = { otp: resp.otp, phone: this.localPhone, otp_sent_counter: 0, otp_sent_date: new Date() }
    
                this.ngxloader.stop();
                this.clearInfoMessages();
              }
            });
            this.ngxloader.stop();
          }
          else {
            this.error = "You must wait an hour before resending again!";
            this.ngxloader.stop();
          }
        }
      });
    }
  }
  handeOtpChange(value: string[]): void {
    value.forEach(input => {
      if (input == '') {
        this.changePasswordForm.get('OtpCode').setValue(null);
      }
    });
  }

  handleFillEvent(value: string): void {
    this.changePasswordForm.get('OtpCode').setValue(value);



  }

  resetOTPTimer() {
    this.showResendOTP = false;
    this.counter = 120
  }

  startOTPTimer() {
    this.resetOTPTimer();
    this.countDown = timer(0, this.tick).subscribe((count) => {
      if (count == 120) {
        //show resent otp button
        this.showResendOTP = true;
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
      }
      --this.counter;
    });
  }
  removePlusFromstring(phoneNumber) {
    return phoneNumber.split('+').join('')
  }

  verifyUserSubscription() {
    this.clearInfoMessages();
    let phone = this.loginForm.get('phone_number').value;
    let email = this.loginForm.get('email_id').value;
    let obj = this;
    if (phone) {
      this.phone_number = this.removePlusFromstring(phone.e164Number);
      let data = {
        phone_number: this.removePlusFromstring(phone.e164Number),
      }
      this.userService.validateUserPhoneNumber(data).subscribe(resp => {
        if (resp.message === 'success (phone number already exists)') {
          /* this.sendOtp();*/
          this.ngxloader.start();
          this.userService.getPhoneOTP(this.phone_number).subscribe(resp => {
            if (resp.OTP) {
              this.userService.sendPhoneOTP(this.phone_number).subscribe(resp => {
                if (resp.message === 'Phone OTP was successfully sent!') {
                  this.phoneOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    phone: this.phone_number,
                    otp_sent_counter: 0,
                    otp_sent_date: new Date(),
                    telesign_ref_id: resp.telesign_ref_id
                  }
  
                }
                else {
                  /* if (tempSteps != 3) { obj.steps = tempSteps; }*/
                }
                this.ngxloader.stop();
              });
            }
            else {
              this.userService.sendPhoneOTP(this.phone_number).subscribe(resp => {
                if (resp.message === 'Phone OTP was successfully sent!') {
                  this.phoneOTP = true;
                  this.showOTP = true;
                  this.startOTPTimer();
                  var data = {
                    otp: resp.otp,
                    phone_number: this.phone_number,
                    otp_sent_counter: this.OTPcounter,
                    otp_sent_date: new Date(),
                    telesign_ref_id: resp.telesign_ref_id
                  }
  
                }
                else {
                  /* if (tempSteps != 3) { obj.steps = tempSteps; }*/
                }
                this.ngxloader.stop();
              });
            }
          });
        } else {
          this.error = resp.message;
          this.ngxloader.stop();
        }
      }, err => {
        this.error = 'User not found.';
        this.ngxloader.stop();
        /* obj.steps = 1;*/
      });


    }//else{
    //  this.error = "Please enter phone number";
    //}
    this.userService.getIdMariaAPIByPhone(this.phone_number).subscribe(resp => {
      if (resp != null && resp.length > 0) {
        this.userId = resp[0].id
        this.initOTP();
      }
      if (resp != null && resp?.length == 0) {
        this.showNumberNotFound = true;
        this.apiCalled = true;
      }
      this.apiCalled = true;
    }, error => {
      this.apiCalled = true;
    })

  }
}
