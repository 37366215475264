import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { CommonHttpClient } from './common/common-http.service';
import { CommonMariaHttpClient } from './common/common-maria-http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from './auth/user-auth.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import * as moment from "moment";
@Injectable({
  providedIn: 'root'
})
export class UserService {
  signUpFormType = {
    first_name: '',
    last_name: '',
    display_name: '',
    email_id: '',
    phone_number: {},
    password: '',
    OtpCode: '',
    confirm_password: '',
    referral_code: '',
    acceptTermsAndConditions: false
  }
  constructor(
    private chttp: CommonHttpClient,
    private mhttp: CommonMariaHttpClient,
    private http: HttpClient,
    private authService: UserAuthService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
  ) { }

  // USER

  isRedirected$ = new BehaviorSubject(false);
  signupFormData$ = new BehaviorSubject(this.signUpFormType);
  Login = data => {
    this.ngxService.start();
    if (data.phone_number) {
      return this.chttp.postRequest('signinWithPhoneNumber', data).pipe(
        tap((res: any) => {
          this.ngxService.stop();
          if (res.message === 'Login successful!') {
            var authResult = { token: res.token, expiresIn: res.expiresIn };
            this.ngxService.stop(); 
              this.authService.storeUser(data.phone_number, authResult);
              //this.router.navigate(['/dashboard']);
            
          } else {
            this.router.navigate(['/login']);
          }
        })
      );
    }
    if (data.email_id) {
      return this.chttp.postRequest('signinWithEmail', data).pipe(
        tap((res: any) => {
          this.ngxService.stop();
          if (res.message === 'Login successful!') {
            var authResult = { token: res.token, expiresIn: res.expiresIn };
            this.ngxService.stop();     
              this.authService.storeUser(data.email_id, authResult);
              //this.router.navigate(['/dashboard']);
            
          } else {
            this.router.navigate(['/login']);
          }
        })
      );
    }
  }



  logout = () => {
    return this.chttp
      .post('user/logout', {}, false)
      .pipe(
        tap((res: any) => {
          if (res.message === 'Successfully signed out') {
            this.authService.removeUser();
            this.router.navigate(['/login']);
          }
        })
      )
      .subscribe();
  }
  autoLogout(): Promise<any> {
    return this.chttp
      .post('user/logout', {}, false)
      .pipe(
        tap((res: any) => {
          if (res.message === 'Successfully signed out') {
            this.authService.removeUser(); 
          }
        })
      )
      .toPromise();  // Convert observable to promise
  }
  

  validateReferralCode = (data) => {
    return this.chttp
      .post('checkreferralCode', data, false);
  }
  getRateAmount = (rateId) => {
    return this.chttp.get('getRate/' + rateId, false);
  }
  getRateName = (name) => {
    return this.chttp.get('getRateName/' + name, false);
  }
  getPlanName = (planId) => {
    return this.chttp.get('getPlan/' + planId, false);
  }
  validateUserPhoneNumber = (data) => {
    return this.chttp
      .postValidate('user/validate-phoneNumber', data, false)
    // .pipe(
    //   tap((res: any) => {
    //     if (res.status === 'success') {
    //      // this.authService.storeUser(res.data);
    //     }
    //   })
    // );
  }

  validateUserEmailAddress = (data) => {
    return this.chttp
      .postValidate('user/validate-emailAddress', data, false)
    // .pipe(
    //   tap((res: any) => {
    //     if (res.status === 'success') {
    //      // this.authService.storeUser(res.data);
    //     }
    //   })
    // );
  }

  //createUser = (data) =>{
  //  return this.chttp
  //      .post('user/create', data, false);
  //}

  createUser = (data) => {
    return this.chttp
      .post('signup', data, false);
  }
  dependentUserCreate = (data) => {
    return this.chttp
      .post('dependentUserCreate', data, false);
  }
  createFlexisipUser = (data) => {
    return this.chttp.post('accounts/create', data, false)
  }
  activateFlexisipUser = (id) => {
    return this.chttp.get('accounts/' + id + '/activate', false)
  }
  deactivateFlexisipUser = (id) => {
    return this.chttp.get('accounts/' + id + '/deactivate', false)
  }
  activateMongoUserByEmail = (email) => {
    return this.chttp.get('activate-email/' + email, false)
  }
  activateMongoUserByPhone = (phone) => {
    return this.chttp.get('activate-phone/' + phone, false)
  }
  updatePromoCode = (id, data) => {
    return this.chttp.put('updatePromo/' + id, data);
  }


  contactUs = (data) => {
    return this.chttp
      .post('contact-us', data, true);
  }


  //subscription related

  getRenewSubscriptionDetailsWithPhoneNumber = (phone_number) => {
    return this.chttp
      .get('renewSubscription-phone/' + phone_number, false);
  }

  getRenewSubscriptionDetailsWithEmail = (email_id) => {
    return this.chttp
      .get('renewSubscription-email/' + email_id, false);
  }

  getSubscriptionDetailsWithPhoneNumber = (phone_number) => {
    return this.chttp
      .get('dashboard-phone/' + phone_number, false);
  }

  getSubscriptionDetailsWithEmail = (email_id) => {
    return this.chttp
      .get('dashboard-email/' + email_id, false);
  }

  getSubscriptionIdWithPhone = (phone_number) => {
    return this.chttp
      .get('getSubscriptionIdWithPhone/' + phone_number, false);
  }

  getSubscriptionDetailsWithEmailFromLink = (email_id) => {
    return this.chttp
      .get('dashboard-email-link/' + email_id, false);
  }
 

  getSubscriptionIdWithEmail = (email_id) => {
    return this.chttp
      .get('getSubscriptionIdWithEmail/' + email_id, false);
  }

  getsubscriptionHistory = () => {
    return this.chttp
      .get('subscription/history', false);
  }

  updateSubscriptionWithPhoneNumber = (phone_number, data) => {
    return this.chttp
      .put('updateSubscriptionWithPhoneNumber/' + phone_number, data);
  }

  updateSubscriptionWithEmail = (email_id, data) => {
    return this.chttp
      .put('updateSubscriptionWithEmail/' + email_id, data);
  }
  isActiveLoopPhone = (phone_number) => {
    return this.chttp
      .get('isActive-pending-phone/' + phone_number);
  }

  isActiveLoopEmail = (email_id) => {
    return this.chttp
      .get('isActive-pending-email/' + email_id);
  }
  sendEmail = (email) => {
    return this.chttp
      .post('sendEmail', { to: email }, false);
  }
  sendFeedback = (data) => {
    return this.chttp
      .post('sendFeedback', data, false);
  }
  sendWelcomeEmail = (data) => {
    return this.chttp
      .post('sendWelcomeEmail', data, false);
  }
  sendUserInformation = (data) => {
    return this.chttp
      .post('sendUserInfo', data, false);
  }
  sendUserPasswordReset = (data) => {
    return this.chttp
      .post('sendUserPasswordReset', data, false);
  }
  sendPhoneOTP = (phone) => {
    return this.chttp
      .post('sendPhoneOTP', { phone_number: phone }, false)
  }
  sendConfirmationLink = (data) => {
    return this.chttp
      .post('sendConfirmationLink', data, false);
  }
  sendEmailChangeNotification = (data) => {
    return this.chttp
      .post('sendEmailChangeNotification', data, false);
  }
  sendPasswordChangeNotification = (data) => {
    return this.chttp
      .post('sendPasswordChangeNotification', data, false);
  }
  sendPasswordResetNotification = (data) => {
    return this.chttp
      .post('sendPasswordResetNotification', data, false);
  }
  sendPhoneLink = (data) => {
    return this.chttp
      .post('sendPhoneLink', data, false);
  }

  getEmailOTP = (email) => {
    return this.chttp
      .get('tempTables/getByEmail/' + email);
  }
  checkEmailOTP = (email, otp_input) => {
    return this.chttp
      .post('checkEmailOTP/' + email, {otp: otp_input});
  }
  getPhoneOTP = (phone) => {
    return this.chttp
      .get('tempTables/getByPhone/' + phone);
  }
  checkPhoneOTP = (phone, otp_input) => {
    return this.chttp
      .post('checkPhoneOTP/' + phone, {otp: otp_input});
  }
  createTempTableRecord = (data) => {
    return this.chttp
      .post('tempTables', data);
  }

  deleteTempEmailRecord = (email) => {
    return this.chttp.delete('tempTables/deleteByEmail/' + email)
  }
  deleteTempPhoneRecord = (phone) => {
    return this.chttp.delete('tempTables/deleteByPhone/' + phone)
  }
  editTempEmailRecord = (email, data) => {
    return this.chttp.put('tempTables/updateByEmail/' + email, data)
  }
  editTempPhoneRecord = (phone, data) => {
    return this.chttp.put('tempTables/updateByPhone/' + phone, data)
  }
  payment = (data) => {
    return this.chttp.post('paymentapi', data)
  }
  authorizeCard = (data) => {
    return this.chttp.post('authorizeCard', data)
  }

  cancelSubWithPhone = (data) => {
    return this.chttp.post('cancelSubscriptionWithPhoneNumber', data)
  }

  cancelSubWithEmail = (data) => {
    return this.chttp.post('cancelSubscriptionWithEmail', data)
  }

  changePaymentWithPhone = (data) => {
    return this.chttp.post('changePaymentWithPhoneNumber', data)
  }

  changePaymentWithEmail = (data) => {
    return this.chttp.post('changePaymentWithEmail', data)
  }

  subHistoryWithPhone = (data) => {
    return this.chttp.post('subHistoryWithPhoneNumber', data)
  }

  subHistoryWithEmail = (data) => {
    return this.chttp.post('subHistoryWithEmail', data)
  }

  verifyTokenByEmail = (email, token) => {
    return this.chttp.post('confirm-email/' + email + '/' + token, { email: email, token: token })
  }
  verifyTokenByPhone = (phone, token) => {
    return this.chttp.post('confirm-phone/' + phone + '/' + token, { email: phone, token: token })
  }
  showToastr(type, msg, title) {
    this.toastr[type](msg, title, {
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
    });
  }
  generateXMLFile(name) {
    return this.chttp.get('generate/xml/' + name)
  }
  generateXMLFileFAM(name, token) {
    return this.chttp.get('provisioning/xml/' + name + "/" + token)
  }
  changePasswordByPhone = (oldPassword,password, phone) => {
    return this.chttp
      .post('change-password-phone/' + phone, { oldPassword: oldPassword, password: password });
  }
  changePasswordByEmail = (oldPassword,password, email) => {
    return this.chttp
      .post('change-password-email/' + email, { oldPassword: oldPassword, password: password });
  }
  changePasswordByPhoneFromLink = (password, phone, otp) => {
    return this.chttp
      .post('change-password-phone-link/' + phone, {password: password, otp: otp });
  }
  changePasswordByEmailFromLink = (password, email, otp) => {
    return this.chttp
      .post('change-password-email-link/' + email, {password: password, otp: otp });
  }
  changeDisplayNameByEmail = (display_name, email) => {
    return this.chttp
      .post('change-display-name-email/' + email, display_name);
  }
  changeDisplayNameByPhone = (display_name, phone) => {
    return this.chttp
      .post('change-display-name-phone/' + phone, display_name);
  }
  changeEmailByPhone = (email, phone) => {
    return this.chttp
      .post('change-email-phone/' + phone, { email: email });
  }
  changeEmailByEmail = (email, email_new) => {
    return this.chttp
      .post('change-email-email/' + email, email_new);
  }
  // changes both email and password
  changeEmailByPhoneFromLink = (phone,email, password) => {
    return this.chttp
      .post('change-email-phone-link/' + phone, {email_id: email , password:password});
  }
  // changes both email and password
  changeEmailByEmailFromLink = (email, email_new,password) => {
    return this.chttp
      .post('change-email-email-link/' + email, { email_id: email_new, password: password });
  }
  changePhoneByEmail = (email, phone) => {
    return this.chttp
      .post('change-phone-number-email/' + email, phone);
  }
  changePhoneByPhone = (phone, phone_new) => {
    return this.chttp
      .post('change-phone-number-phone/' + phone, phone_new);
  }
  async dashboardAutoLogin(phone: string, password: string): Promise<any> {
    if (localStorage.getItem('0')) {
      await this.autoLogout(); 
    }
  
    return this.chttp.get('dashboard-auto-login/' + phone + '/' + password).pipe(
      tap((res: any) => {
        this.ngxService.stop();
        if (res.message === 'Login successful!') {
          var authResult = { token: res.token, expiresIn: res.expiresIn };
          this.ngxService.stop();
          if (phone != null) {
            this.authService.storeUser(phone, authResult);
          }
        } else {
          this.router.navigate(['/login']);
        }
      })
    ).toPromise();  
  }
  
  
  changePasswordFAM = (phone, data) => {
    return this.chttp
      .post('change-password/' + "+" + phone, data);
  }
  getDependentUsersEmail = (email) => {
    return this.chttp
      .get('dependent-users-email/' + email);
  }
  getDependentUsersPhone = (phone) => {
    return this.chttp
      .get('dependent-users-phone/' + phone);
  }
  changePasswordFAMProv = (data) => {
    return this.mhttp
      .post('change-password-prov', data);
  }
  changePasswordMaria = (data) => {
    return this.mhttp
      .post('change-password', data);
  }
  changePasswordFAMFromLink = (phone, data) => {
    return this.chttp
      .post('change-password-link/' + "+" + phone, data);
  }
  changeEmailFAM = (phone, data) => {
    return this.chttp
      .post('change-email/' + "+" + phone, data);
  }
  changeEmailMariaAPIByPhone = (phone, data) => {
    return this.mhttp
      .post('change-email-phone/' + "+" + phone, data);
  }
  changeEmailMariaAPIByEmail = (email, data) => {
    return this.mhttp
      .post('change-email-email/' + email, data);
  }
  changeDisplayNameMariaAPIByPhone = (phone, data) => {
    return this.mhttp
      .post('change-display-name-phone/' + "+" + phone, data);
  }
  changeDisplayNameMariaAPIByEmail = (email, data) => {
    return this.mhttp
      .post('change-display-name-email/'  + email, data);
  }
  updateTempUserMariaAPIByPhone = (phone, data) => {
    return this.mhttp
      .post('update-temp-user-phone/' + "+" + phone, data);
  }
  updateTempUserMariaAPIByEmail = (email, data) => {
    return this.mhttp
      .put('update-temp-user-email/' + email, data);
  }
  changePhoneNumberMariaAPIByPhone = (phone, data) => {
    return this.mhttp
      .post('change-phone-number-phone/' + "+" + phone, data);
  }
  changePhoneNumberMariaAPIByPhoneProv = (phone, data) => {
    return this.mhttp
      .post('change-phone-number-phone-prov/' + "+" + phone, data);
  }
  changePhoneNumberMariaAPIByEmail = (email, data) => {
    return this.mhttp
      .post('change-phone-number-email/' + email, data);
  }
  getIdMariaAPIByPhone = (phone) => {
    return this.mhttp
      .get('get-id-phone/' + "+" + phone);
  }

  getIdMariaAPIByEmail = (email, ) => {
    return this.mhttp
      .get('get-id-email/' + email);
  }

  changePasswordFromlink = (password, userId, phone_number) => {
    return this.mhttp
      .post('/change-password', { password: password,id:userId,phone_number:phone_number });
  }

  deleteAccountPhone = (phone) => 
  {
    return this.chttp
    .delete('delete-account-phone/' + phone);
  }
  deleteAccountEmail = (email) => 
  {
    return this.chttp
    .delete('delete-account-email/' + email);
  }
  deleteAccountPhoneFAM = (phone) => 
  {
    return this.mhttp
    .delete('delete-account-phone/' + "+" + phone);
  }
  deleteAccountEmailFAM = (email) => 
  {
    return this.mhttp
    .delete('delete-account-email/' + email);
  }
}
